import React, { useEffect, useRef, useState } from 'react';
import {createChart, CrosshairMode, LineStyle} from 'lightweight-charts';
import useStore from '../Store/market_greeks_data_store';
import SERIES from './seriesconfig';
import './styles.css'; // Import your CSS file
import createSRSeries from './createSRSeries';

const MarketStateChart = () => {
    const chartContainerRef = useRef(null);
    const gexChartContainerRef = useRef(null); // Ref for the GEX chart
    const vvixChartContainerRef = useRef(null);
    const chartRef = useRef(null);
    const gexChartRef = useRef(null); // Ref for the GEX chart instance
    const vvixChartRef = useRef(null);

    // Add series references
    const candlestickSeriesRef = useRef({});
    const gexSeriesRef = useRef({});
    const vvixSeriesRef = useRef({});

    // Use Zustand store values
    const currentData = useStore((state) => state.currentData);
    const showSrLines = useStore((state) => state.showSrLines);
    const candlestickData = useStore((state) => (currentData === 'spx' ? state.spxData : state.vixData));
    const topStrikesData = useStore((state) => state.topStrikes); // Access topStrikes from the store
    const spxGexData = useStore((state) => state.spxGexData);
    const vvixData = useStore((state) => state.vvixData);

    // Get the most recent close value from candlestickData
    const closeValue = candlestickData.length > 0 ? candlestickData[candlestickData.length - 1].close : 0;

    const [srLines, setSrLines] = useState({});  // State to store SR line references
    const [isVixInitialized, setIsVixInitialized] = useState(false);



useEffect(() => {
    if (!candlestickData || candlestickData.length === 0 || !chartContainerRef.current) {
        return;
    }

    const sortedCandlestickData = [...candlestickData].sort((a, b) => new Date(a.date) - new Date(b.date));

    if (!chartRef.current) {
        chartRef.current = createChart(chartContainerRef.current, {
            width: window.innerWidth * 0.95,
            height: window.innerHeight * 0.50,
            timeScale: {
                fixLeftEdge: true,
                leftBarStaysOnScroll: true,
                borderVisible: false,
                borderColor: "#000000",
                visible: false,
                timeVisible: false,
                secondsVisible: false,
                tickMarkFormatter: () => '',
                priceLineVisible: false,
                rightOffset: 10,
                barSpacing:10
            },
            layout: {
                background: {
                    type: 'solid',
                    color: '#000000',
                },
                textColor: '#d1d4dc',
            },
            grid: {
                vertLines: {
                    color: 'rgba(42, 46, 57, 0)',
                },
                horzLines: {
                    color: 'rgba(87,87,87,0.23)',
                },
            },
            crosshair: {
                mode: CrosshairMode.Normal,
                vertLine: {
                    width: 8,
                    color: '#C3BCDB44',
                    style: LineStyle.Solid,
                    labelBackgroundColor: '#B18800',
                },
                horzLine: {
                    width: 8,
                    color: '#C3BCDB44',
                    style: LineStyle.Solid,
                    labelBackgroundColor: '#B18800',
                },
            },
            rightPriceScale: {
                scaleMargins: {
                    top: 0.2,
                    bottom: 0.2,
                },
            },
        });

        const candlestickSeries = chartRef.current.addCandlestickSeries(
            SERIES.find((series) => series.key === 'candlestick').chartOptions
        );

        candlestickSeries.setData(
            sortedCandlestickData.map((item) => ({
                time: {
                    year: new Date(item.date).getUTCFullYear(),
                    month: new Date(item.date).getUTCMonth() + 1,
                    day: new Date(item.date).getUTCDate(),
                },
                open: parseFloat(item.open),
                high: parseFloat(item.high),
                low: parseFloat(item.low),
                close: parseFloat(item.close),
            }))
        );

        candlestickSeriesRef.current['candlestick'] = candlestickSeries;
    }

    if (showSrLines) {
        // Create or update SR lines whenever topStrikesData changes
        const srSeries = createSRSeries(topStrikesData, chartRef.current);
        setSrLines(srSeries);  // Store the SR line references in state
    } else {
        // Remove SR lines when toggling to VIX data
        Object.entries(srLines).forEach(([key, lineSeries]) => {
            if (lineSeries) {
                try {
                    chartRef.current.removeSeries(lineSeries);
                } catch (error) {
                    // console.error(`Failed to remove SR line: ${key}`, error);
                }
            }
        });
        Object.keys(srLines).forEach((key) => delete srLines[key]);
    }

    const handleResize = () => {
        const newWidth = window.innerWidth * 0.95;
        const newHeight = window.innerHeight * 0.50;
        chartRef.current.resize(newWidth, newHeight);
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => {
        window.removeEventListener('resize', handleResize);
        if (chartRef.current) {
            chartRef.current.remove();
            chartRef.current = null;
        }
    };
}, [candlestickData, topStrikesData, closeValue, showSrLines, currentData]); // Add showSrLines and currentData to dependencies



const toggleData = (data) => {
    // console.log(`Toggling data to: ${data}`);
    setIsVixInitialized(false); // Reset initialization state
    useStore.getState().toggleData(data);
};



    useEffect(() => {
        // console.log("Initializing GEX chart...");

        if (!spxGexData || spxGexData.length === 0 || !gexChartContainerRef.current) {
            // console.log("GEX chart data or container is not ready.");
            return;
        }

        const sortedGexData = [...spxGexData].sort((a, b) => new Date(a.date) - new Date(b.date));

        if (!gexChartRef.current) {
            gexChartRef.current = createChart(gexChartContainerRef.current, {
                width: window.innerWidth * 0.95,
                height: window.innerHeight * 0.20,
                timeScale: {
                    fixLeftEdge: true,
                    // fixRightEdge: true,
                    // rightBarStaysOnScroll: true,
                    leftBarStaysOnScroll: true,
                    borderVisible: false,
                    borderColor: "#000000",
                    visible: false,
                    timeVisible: false,
                    secondsVisible: false,
                    tickMarkFormatter: () => '',
                    priceLineVisible: false,
                    rightOffset: 20

                },
                crosshair: {
                    mode: CrosshairMode.Normal,
                    vertLine: {
                        width: 8,
                        color: '#C3BCDB44',
                        style: LineStyle.Solid,
                        labelBackgroundColor: '#B18800',
                    },
                    horzLine: {
                        width: 8,
                        color: '#C3BCDB44',
                        style: LineStyle.Solid,
                        labelBackgroundColor: '#B18800',
                    },
                },
                layout: {
                    background: {
                        type: 'solid',
                        color: '#000000',
                    },
                    textColor: '#d1d4dc',
                },
                grid: {
                    vertLines: {
                        color: 'rgba(42, 46, 57, 0)',
                    },
                    horzLines: {
                        color: 'rgba(87, 87, 87, 0.23)',
                    },
                },
                rightPriceScale: {
                    scaleMargins: {
                        top: 0.2,
                        bottom: 0.2,
                    },
                },
            });

            const gexSeries = gexChartRef.current.addBaselineSeries(
                SERIES.find((series) => series.key === 'market_gex').chartOptions
            );

            gexSeries.setData(
                sortedGexData.map((item) => ({
                    time: {
                        year: new Date(item.date).getUTCFullYear(),
                        month: new Date(item.date).getUTCMonth() + 1,
                        day: new Date(item.date).getUTCDate(),
                    },
                    value: parseFloat(item.z_5ma),
                }))
            );

            gexSeriesRef.current['market_gex'] = gexSeries;
            // console.log("GEX chart and series initialized.");
        }

        const handleResize = () => {
            const newWidth = window.innerWidth * 0.95;
            const newHeight = window.innerHeight * 0.20;
            gexChartRef.current.resize(newWidth, newHeight);
        };

        window.addEventListener('resize', handleResize);
        handleResize();

        return () => {
            window.removeEventListener('resize', handleResize);
            if (gexChartRef.current) {
                gexChartRef.current.remove();
                gexChartRef.current = null;
            }
        };
    }, [spxGexData]);

    useEffect(() => {
        // console.log("Initializing VVIX chart...");

        if (!vvixData || vvixData.length === 0 || !vvixChartContainerRef.current) {
            // console.log("VVIX chart data or container is not ready.");
            return;
        }

        const sortedVvixData = [...vvixData].sort((a, b) => new Date(a.date) - new Date(b.date));

        if (!vvixChartRef.current) {
            vvixChartRef.current = createChart(vvixChartContainerRef.current, {
                width: window.innerWidth * 0.95,
                height: window.innerHeight * 0.20,
                timeScale: {
                    fixLeftEdge: true,
                    // fixRightEdge: true,
                    // rightBarStaysOnScroll: true,
                    leftBarStaysOnScroll: true,
                    borderVisible: false,
                    borderColor: "#000000",
                    visible: true,
                    priceLineVisible: false,
                    rightOffset: 20


                },
                crosshair: {
                    mode: CrosshairMode.Normal,
                    vertLine: {
                        width: 8,
                        color: '#C3BCDB44',
                        style: LineStyle.Solid,
                        labelBackgroundColor: '#B18800',
                    },
                    horzLine: {
                        width: 8,
                        color: '#C3BCDB44',
                        style: LineStyle.Solid,
                        labelBackgroundColor: '#B18800',
                        },
                },
                layout: {
                    background: {
                        type: 'solid',
                        color: '#000000',
                    },
                    textColor: '#d1d4dc',
                },
                grid: {
                    vertLines: {
                        color: 'rgba(42, 46, 57, 0)',
                    },
                    horzLines: {
                        color: 'rgba(87, 87, 87, 0.23)',
                    },
                },
                rightPriceScale: {
                    scaleMargins: {
                        top: 0.2,
                        bottom: 0.2,
                    },
                },
            });

            // Add the VVIX average Z-Score line series
            // Add the VVIX average Z-Score baseline series
            const vvixAvgSeries = vvixChartRef.current.addBaselineSeries({
                ...SERIES.find((series) => series.key === 'vvix_avg_z_score').chartOptions,
            });

            // Add the VVIX super smooth Z-Score baseline series
            const vvixSuperSmoothSeries = vvixChartRef.current.addBaselineSeries({
                ...SERIES.find((series) => series.key === 'vvix_super_smooth_z_score').chartOptions,
            });


            // Set the data for the VVIX series
            vvixAvgSeries.setData(
                sortedVvixData.map((item) => ({
                    time: {
                        year: new Date(item.date).getUTCFullYear(),
                        month: new Date(item.date).getUTCMonth() + 1,
                        day: new Date(item.date).getUTCDate(),
                    },
                    value: parseFloat(item.vvix_avg_z_score),
                }))
            );

            vvixSuperSmoothSeries.setData(
                sortedVvixData.map((item) => ({
                    time: {
                        year: new Date(item.date).getUTCFullYear(),
                        month: new Date(item.date).getUTCMonth() + 1,
                        day: new Date(item.date).getUTCDate(),
                    },
                    value: parseFloat(item.vvix_super_smooth_z_score),
                }))
            );

            // Store the series in the vvixSeriesRef
            vvixSeriesRef.current['vvix_avg_z_score'] = vvixAvgSeries;
            vvixSeriesRef.current['vvix_super_smooth_z_score'] = vvixSuperSmoothSeries;
            // console.log("VVIX chart and series initialized.");
        }

        // Handle resizing of the VVIX chart
        const handleResize = () => {
            const newWidth = window.innerWidth * 0.95;
            const newHeight = window.innerHeight * 0.20;
            vvixChartRef.current.resize(newWidth, newHeight);
        };

        window.addEventListener('resize', handleResize);
        handleResize();

        return () => {
            window.removeEventListener('resize', handleResize);
            if (vvixChartRef.current) {
                vvixChartRef.current.remove();
                vvixChartRef.current = null;
            }
        };
    }, [vvixData]);


    useEffect(() => {
        const checkInitialization = () => {
            if (
                chartRef.current &&
                gexChartRef.current &&
                vvixChartRef.current &&
                candlestickSeriesRef.current['candlestick'] &&
                gexSeriesRef.current['market_gex'] &&
                vvixSeriesRef.current['vvix_avg_z_score']
            ) {
                // Mark the series as initialized
                setIsVixInitialized(true);

                const mainChart = chartRef.current;
                const gexChart = gexChartRef.current;
                const vvixChart = vvixChartRef.current;
                const mainSeries = candlestickSeriesRef.current['candlestick'];
                const gexSeries = gexSeriesRef.current['market_gex'];
                const vvixSeries = vvixSeriesRef.current['vvix_avg_z_score'];

                const safelyApply = (fn) => {
                    if (mainChart && gexChart && vvixChart) {
                        fn();
                    } else {
                        console.log('Charts not ready');
                    }
                };

                const syncTimeRange = (timeRange) => {
                    safelyApply(() => {
                        gexChart.timeScale().setVisibleLogicalRange(timeRange);
                        vvixChart.timeScale().setVisibleLogicalRange(timeRange);
                    });
                };

                const reverseSyncTimeRange = (timeRange) => {
                    safelyApply(() => {
                        mainChart.timeScale().setVisibleLogicalRange(timeRange);
                    });
                };

                mainChart.timeScale().subscribeVisibleLogicalRangeChange(syncTimeRange);
                gexChart.timeScale().subscribeVisibleLogicalRangeChange(reverseSyncTimeRange);
                vvixChart.timeScale().subscribeVisibleLogicalRangeChange(reverseSyncTimeRange);

                // Sync the crosshair between charts
                const handleMainChartCrosshairMove = (param) => {
                    safelyApply(() => {
                        const dataPoint = getCrosshairDataPoint(mainSeries, param);
                        if (dataPoint) {
                            syncCrosshair(gexChart, gexSeries, dataPoint);
                            syncCrosshair(vvixChart, vvixSeries, dataPoint);
                        } else {
                            gexChart.clearCrosshairPosition();
                            vvixChart.clearCrosshairPosition();
                        }
                    });
                };

                const handleGexChartCrosshairMove = (param) => {
                    safelyApply(() => {
                        const dataPoint = getCrosshairDataPoint(gexSeries, param);
                        if (dataPoint) {
                            syncCrosshair(mainChart, mainSeries, dataPoint);
                            syncCrosshair(vvixChart, vvixSeries, dataPoint);
                        } else {
                            mainChart.clearCrosshairPosition();
                            vvixChart.clearCrosshairPosition();
                        }
                    });
                };

                const handleVvixChartCrosshairMove = (param) => {
                    safelyApply(() => {
                        const dataPoint = getCrosshairDataPoint(vvixSeries, param);
                        if (dataPoint) {
                            syncCrosshair(mainChart, mainSeries, dataPoint);
                            syncCrosshair(gexChart, gexSeries, dataPoint);
                        } else {
                            mainChart.clearCrosshairPosition();
                            gexChart.clearCrosshairPosition();
                        }
                    });
                };

                mainChart.subscribeCrosshairMove(handleMainChartCrosshairMove);
                gexChart.subscribeCrosshairMove(handleGexChartCrosshairMove);
                vvixChart.subscribeCrosshairMove(handleVvixChartCrosshairMove);

                return () => {
                    safelyApply(() => {
                        mainChart.timeScale().unsubscribeVisibleLogicalRangeChange(syncTimeRange);
                        gexChart.timeScale().unsubscribeVisibleLogicalRangeChange(reverseSyncTimeRange);
                        vvixChart.timeScale().unsubscribeVisibleLogicalRangeChange(reverseSyncTimeRange);
                        mainChart.unsubscribeCrosshairMove(handleMainChartCrosshairMove);
                        gexChart.unsubscribeCrosshairMove(handleGexChartCrosshairMove);
                        vvixChart.unsubscribeCrosshairMove(handleVvixChartCrosshairMove);
                    });
                };
            } else {
                setTimeout(checkInitialization, 500); // Re-check after 500ms
            }
        };

        if (!isVixInitialized) {
            checkInitialization();
        }
    }, [chartRef.current, gexChartRef.current, vvixChartRef.current, isVixInitialized]);




function getCrosshairDataPoint(series, param) {
    if (!param || !param.time || !param.seriesData || typeof param.seriesData.get !== 'function') {
        return null;
    }

    const dataPoint = param.seriesData.get(series);

    if (dataPoint) {
        if (dataPoint.close !== undefined) {
            return { time: param.time, value: dataPoint.close };
        } else if (dataPoint.value !== undefined) {
            return { time: param.time, value: dataPoint.value };
        }
    }

    return null;
}

function syncCrosshair(chart, series, dataPoint) {
    if (dataPoint && dataPoint.time !== undefined && dataPoint.value !== undefined) {
        try {
            chart.setCrosshairPosition(dataPoint.value, dataPoint.time, series);
        } catch (error) {
            chart.clearCrosshairPosition();
        }
    } else {
        chart.clearCrosshairPosition();
    }
}
    return (
        <div className="chart-component-container">
            {/* Message section placed outside of any chart containers to avoid affecting layout */}
            <div className="message-section" style={{ color: '#B18800', fontSize: '12pt', padding: '10px 0' }}>
                In the coming weeks, SPX Gamma will be transitioning to a model that will require users to log in before accessing our dashboards. We will offer a 30-day free trial to all users during this transition period. It has been a pleasure to provide this service free of charge over the past 18 months. As we continue to grow, it’s necessary to begin covering the costs associated with developing and maintaining the platform, including data feeds, cloud hosting, and more. We truly appreciate your continued support and look forward to providing even more value as we move forward together.
            </div>

            {/* Toggle buttons */}
            <div className="toggle-buttons-container">
                <button
                    onClick={() => toggleData('spx')}
                    className={`toggle-button ${useStore.getState().currentData === 'spx' ? 'active' : ''}`}
                >
                    SPX
                </button>
                <button
                    onClick={() => toggleData('vix')}
                    className={`toggle-button ${useStore.getState().currentData === 'vix' ? 'active' : ''}`}
                >
                    VIX
                </button>
            </div>

            {/* Main chart container */}
            <div className="chart-container" style={{ position: 'relative' }}>
                <div ref={chartContainerRef} className="chart" />
                <div className="overlay">Market State Dashboard</div> {/* Overlay for Main Chart */}
            </div>

            {/* GEX chart container */}
            <div className="chart-container" style={{ position: 'relative' }}>
                <div ref={gexChartContainerRef} className="chart" />
                <div className="overlay">Market GEX</div> {/* Overlay for GEX Chart */}
            </div>

            {/* VVIX chart container */}
            <div className="chart-container" style={{ position: 'relative' }}>
                <div ref={vvixChartContainerRef} className="chart" />
                <div className="overlay">Volatility Oscillator</div> {/* Overlay for VVIX Chart */}
            </div>
        </div>
    );
};

export default MarketStateChart;










