import React from 'react';
import MarketGreeksWssClient from './Components/market_greeks_wss_client';
import MarketStateChart from './Components/market_state';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <div className="app-container">
          <MarketGreeksWssClient /> {/* This will initiate the WebSocket connection */}
          <MarketStateChart /> {/* This will render the chart */}
        </div>
      </header>
    </div>
  );
}

export default App;





